import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';

import {
  FormattedAssigneeOptions,
  IsFetching,
} from '../../redux/modules/Todos/selectors';
import { validateDate } from '../../utils/FeatureTypes';
import { Button, MyModal } from '../common';
import { Select, Textarea, TextField } from '../formik';
import {
  assignTaskTodo,
  getAssigneeOptions,
  updateTodo,
} from '../../redux/modules/Todos/operations';

import './AssignTodo.scss';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/esm/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: '450px',
    minHeight: '250px',
    margin: 'auto',
    padding: 0,
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 420,
  },
};

export const priorityOptions = [
  { label: '--', value: 0, color: '#0d2238' },
  { label: 'Low', value: 1, color: '#86dbc1' },
  { label: 'Medium', value: 2, color: '#c2b946' },
  { label: 'High', value: 3, color: '#db6949' },
];

const selectStyles = {
  option: (styles, { data, isSelected }) => ({
    ...styles,
    backgroundColor: (isSelected && data.color) || null,
    color: (isSelected && 'white') || data.color,
    cursor: 'pointer',
    ':hover': {
      ...styles['hover'],
      backgroundColor: data.color,
      color: 'white',
    },
  }),
  input: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
};

export const AssignTodo = ({
  handleDeleteTodo,
  isActionDisabled,
  isInline,
  isModalOpen,
  isMyDashboard,
  isNew,
  todoMeta,
  toggleModal,
}) => {
  const { account_id, id, initTodoVals, project_template_id } = todoMeta;
  const dispatch = useDispatch();

  const isFetching = useSelector(IsFetching);
  const assigneeOptions = useSelector(state =>
    FormattedAssigneeOptions(state, account_id, project_template_id),
  );

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isFetching && isModalOpen && assigneeOptions.length === 0 && !initialized) {
      setInitialized(true);
      dispatch(getAssigneeOptions(account_id, project_template_id, isMyDashboard));
    }
    if (!isModalOpen && initialized) {
      setInitialized(false);
    }
  }, [
    assigneeOptions,
    account_id,
    dispatch,
    id,
    initialized,
    isFetching,
    isMyDashboard,
    project_template_id,
    isModalOpen,
  ]);

  return (
    <>
      {isInline && (
        <Button
          buttonType="icon"
          className={`${!isNew && 'assignTodo__edit'}`}
          isDisabled={isActionDisabled}
          onClick={toggleModal}
          size="sm"
          tooltip={isNew ? 'Assign' : 'Edit Todo'}
        >
          <FontAwesomeIcon icon={['fal', isNew ? 'user-plus' : 'edit']} />
        </Button>
      )}
      {isModalOpen && (
        <MyModal isOpen={isModalOpen} onRequestClose={toggleModal} style={customStyles}>
          <div className="assignTodo" onClick={e => e.stopPropagation()}>
            <FontAwesomeIcon
              className="assignTodo__exit"
              onClick={toggleModal}
              icon={['fal', 'times']}
            />
            <h2>
              {`${isNew ? 'Assign' : 'Update'} To-do`}
              {!isNew && project_template_id !== 88 && (
                <Button
                  buttonType="link"
                  isWarning
                  size="sm"
                  onClick={() =>
                    handleDeleteTodo(id, false, account_id).then(e => toggleModal(e))
                  }
                >
                  <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                </Button>
              )}
            </h2>
            <Formik
              initialValues={{
                deadline: '',
                selectedAssignee: '',
                selectedPriority: priorityOptions[0],
                label: `Complete Task`,
                ...initTodoVals,
              }}
              onSubmit={({ deadline, label, selectedAssignee, selectedPriority }) => {
                const submitFunc = isNew ? assignTaskTodo : updateTodo;
                if (deadline) {
                  deadline = dayjs(deadline + 'T21:00:00.000Z', 'MM/DD/YYYY').format();
                } else {
                  deadline = null;
                }
                dispatch(
                  submitFunc(
                    id,
                    {
                      deadline,
                      user_id: selectedAssignee && selectedAssignee.user_id,
                      partner_id: selectedAssignee && selectedAssignee.partner_id,
                      label: label,
                      base_level: selectedPriority.value,
                    },
                    isMyDashboard,
                    account_id,
                    true,
                  ),
                ).then(toggleModal, toggleModal);
              }}
              validateOnChange={false}
            >
              {formikProps => (
                <Form>
                  <Field component={Textarea} label="To-do Label:" name="label" />
                  <Field
                    component={Select}
                    label="Assign a user or partner"
                    name="selectedAssignee"
                    options={assigneeOptions}
                  />
                  <Field
                    component={Select}
                    isSearchable={false}
                    label="Assign a priority level"
                    name="selectedPriority"
                    options={priorityOptions}
                    selectStyles={selectStyles}
                  />
                  <Field
                    component={TextField}
                    label="Assign a due date"
                    myType="date"
                    name="deadline"
                    placeholder="ex. 04/24/2021"
                    seperator="/"
                    validate={val => validateDate(val, true)}
                  />
                  <div className="assignTodo__buttons">
                    <Button
                      buttonType="secondary"
                      isFetching={isFetching}
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button isFetching={isFetching} type="submit">
                      Assign
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </MyModal>
      )}
    </>
  );
};

export const MultiAssignTodos = ({
  account_id,
  handleDeleteTodos,
  isActionDisabled,
  isModalOpen,
  isMyDashboard,
  handleUpdateTodos,
  toggleModal,
}) => {
  const dispatch = useDispatch();

  const isFetching = useSelector(IsFetching);
  const [initialized, setInitialized] = useState(false);
  const assigneeOptions = useSelector(state =>
    FormattedAssigneeOptions(state, account_id, 'all'),
  );

  useEffect(() => {
    if (isModalOpen && assigneeOptions.length === 0 && initialized) {
      setInitialized(true);
      dispatch(getAssigneeOptions(account_id, 'all', isMyDashboard));
    }
    if (!isModalOpen && !initialized) {
      setInitialized(false);
    }
  }, [assigneeOptions, account_id, dispatch, initialized, isModalOpen, isMyDashboard]);

  return (
    <>
      <Button
        buttonType="icon"
        className="assignTodo__edit"
        isDisabled={isActionDisabled}
        onClick={toggleModal}
        size="sm"
        tooltip="Edit Selected Todos"
      >
        <FontAwesomeIcon icon={['fal', 'edit']} />
      </Button>
      {isModalOpen && (
        <MyModal isOpen={isModalOpen} onRequestClose={toggleModal} style={customStyles}>
          <div className="assignTodo">
            <FontAwesomeIcon
              className="assignTodo__exit"
              onClick={toggleModal}
              icon={['fal', 'times']}
            />
            <h2>
              Update Todos
              <Button
                buttonType="link"
                isWarning
                size="sm"
                onClick={() => handleDeleteTodos()}
              >
                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
              </Button>
            </h2>
            <Formik
              initialValues={{
                deadline: '',
                selectedAssignee: '',
                selectedPriority: '',
              }}
              onSubmit={handleUpdateTodos}
              validateOnChange={false}
            >
              {formikProps => (
                <Form>
                  <Field
                    component={Select}
                    isSearchable={false}
                    label="Assign a priority level"
                    name="selectedPriority"
                    options={priorityOptions}
                    selectStyles={selectStyles}
                  />
                  {!isMyDashboard && (
                    <Field
                      component={Select}
                      label="Assign a user or partner"
                      name="selectedAssignee"
                      options={assigneeOptions}
                    />
                  )}
                  <Field
                    component={TextField}
                    label="Assign a due date"
                    myType="date"
                    name="deadline"
                    placeholder="ex. 04-24-2021"
                    validate={val => validateDate(val, true)}
                  />
                  <div className="assignTodo__buttons">
                    <Button
                      buttonType="secondary"
                      isFetching={isFetching}
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button isFetching={isFetching} type="submit">
                      Assign
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </MyModal>
      )}
    </>
  );
};

AssignTodo.propTypes = {
  account_id: PropTypes.number,
  handleDeleteTodo: PropTypes.func,
  id: PropTypes.number,
  initTodoVals: PropTypes.shape({
    deadline: PropTypes.string,
    label: PropTypes.string,
    selectedAssignee: PropTypes.object,
    selectedPriority: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  }),
  isModalOpen: PropTypes.bool,
  isMyDashboard: PropTypes.bool,
  isNew: PropTypes.bool,
  project_template_id: PropTypes.number,
  toggleModal: PropTypes.func,
};
