import React, { Fragment, useState } from 'react';
import { DropdownMenu } from 'react-dd-menu';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IsDocumentTransaction } from '../../redux/modules/Transaction/selectors';
import Button from '../common/Button';
import InputStyledCheckbox from '../common/InputStyledCheckbox';

import './FinalizeReviewModal.scss';
import { get, isArray, isEmpty, isObject, startCase } from 'lodash-es';
import { IsAccountAdmin } from '../../redux/modules/Profile/selectors';
import { InputSelect, InputStyledTextField } from '../inputs';
import { TwoCharCountryData } from '../../data/CountryData';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { setNotice } from '../../redux/modules/UI/actions';
import { addPaymentMethod } from '../../redux/modules/Billing/operations';
import { CurrentCompany } from '../../redux/modules/Company/selectors';
import { BrandMeta } from '../../redux/modules/UI/selectors';
import { MyModal } from '../common';

const bem = elementName => `finalizeReviewModal${elementName ? '__' + elementName : ''}`;

const ReviewInfo = reviewObj =>
  Object.entries(reviewObj).map(([resourceKey, resourceArr], index) => (
    <div className="finalizeReviewModal__header" key={`review-${resourceKey}-${index}`}>
      {resourceArr.map((resource, resourceInd) => (
        <Fragment key={`resource-array-${resourceInd}`}>
          {resource.resource && (
            <h4>
              <em>{`${resource.resource} (${resource['Custom Label']})`}</em>
            </h4>
          )}
          {resource.resource && (
            <div className="finalizeReviewModal__row">
              {Object.entries(resource).map(([key, value], ind) => {
                if (
                  key !== 'resource' &&
                  key !== 'Company Name' &&
                  key !== 'Name' &&
                  key !== 'Custom Label'
                ) {
                  let updatedVal = value;

                  if (isObject(value)) {
                    if (value.isDocument) {
                      return (
                        <Fragment key={`review-info-${key}-${ind}`}>
                          <h4>
                            {key}: <p>{value.value}</p>
                          </h4>
                        </Fragment>
                      );
                    }
                    if (value.isLink) {
                      return (
                        <Fragment key={`review-info-${key}-${ind}`}>
                          <h4>
                            {key}: <a href={value.path}>{value.label}</a>
                          </h4>
                        </Fragment>
                      );
                    }
                    if (isArray(value)) {
                      return (
                        <Fragment key={`review-info-link-${key}}-${ind}`}>
                          <h4>{key}:</h4>
                          <div className="finalizeReviewModal__reviewRow">
                            {value.map((e, linkIndex) => (
                              <Fragment key={`review-info-link-${e.label}}-${ind}`}>
                                <p>
                                  Link #{linkIndex + 1}:
                                  <a
                                    href={e.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {e.label}
                                  </a>
                                </p>
                              </Fragment>
                            ))}
                          </div>
                        </Fragment>
                      );
                    }
                    if (!Object.values(value).join('')) {
                      updatedVal = 'None Given';
                    } else {
                      return (
                        <Fragment key={`review-info-${key}-${ind}`}>
                          <h4>{key}:</h4>
                          <p>{value.line_one}</p>
                          {value.line_two.length > 0 && <p>{value.line_two}</p>}
                          <p>{`${startCase(value.city)}, ${value.state} ${value.zip}`}</p>
                        </Fragment>
                      );
                    }
                  } else if (value.indexOf('|') !== -1) {
                    updatedVal = updatedVal.split('|').join(', ');
                  }
                  return (
                    <h4 key={`review-info-${key}-${ind}`}>
                      {key}: <p>{updatedVal || 'Not Given'}</p>
                    </h4>
                  );
                }
                return null;
              })}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  ));

const FinalizeReviewModal = ({
  addons,
  amountTotal,
  documentCredits,
  fees,
  feesTotal,
  isAddonsHidden,
  isAssessment,
  isOpen,
  isOutsideForm,
  isPaymentMethodShowing,
  isRedlineAvailable,
  isTotalShowing,
  onClose,
  handleFinalize,
  params,
  partnerCoverage: { creditsCoveredBy, feesCoveredBy } = {},
  reviewInfo,
  room_use,
}) => {
  const brandMeta = useSelector(BrandMeta);
  const currentCompany = useSelector(CurrentCompany);
  const isPaymentsHidden = !!feesCoveredBy && !currentCompany.partner_id;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { transactionId } = params;
  const { REACT_APP_LOG_ENV } = process.env;
  const isDevEnv = REACT_APP_LOG_ENV !== 'production';

  const isDocumentTransaction = useSelector(state =>
    IsDocumentTransaction(state, transactionId),
  );
  const isAdmin = useSelector(IsAccountAdmin);

  const [isAutoSend, setIsAutoSend] = useState(true);
  const [isDocEditingEnabled, setIsDocEditingEnabled] = useState(false);
  const [isDevPandadoc, setDevPandadoc] = useState(false);
  const [isPricingOpen, setPricingOpen] = useState(false);

  const [country, setCountry] = useState({
    value: 'US',
    label: 'United States of America (the)',
  });
  const [isDefault, setIsDefault] = useState(true);
  const [name, setName] = useState('');
  const [stripeError, setStripeError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onFinalizeClick = async () => {
    setSubmitting(true);
    if (!isPaymentMethodShowing) {
      return handleFinalize(isAutoSend, isDevPandadoc, isDocEditingEnabled).then(
        e => {
          setSubmitting(false);
          onClose();
        },
        error => setSubmitting(false),
      );
    }
    if (!name) {
      setSubmitting(false);
      return dispatch(
        setNotice({
          type: 'error',
          message: 'Please provide a name for your payment card',
        }),
      );
    } else if (!country) {
      setSubmitting(false);
      return dispatch(
        setNotice({
          type: 'error',
          message: 'Please provide a country for your payment card',
        }),
      );
    }
    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement, {
      name,
      country: country.value,
    });

    if (!error) {
      await dispatch(
        addPaymentMethod(
          token,
          isDefault,
          'Payment method saved, submitting Task. Please be patient as we finalize everything on our end.',
        ),
      ).then(
        e => {
          if (e && !e.error) {
            return handleFinalize(isAutoSend, isDevPandadoc, isDocEditingEnabled).then(
              e => {
                setSubmitting(false);
                onClose();
              },
              error => setSubmitting(false),
            );
          }
        },
        error => setSubmitting(false),
      );
    } else {
      if (error.type === 'validation_error') {
        setStripeError(error.message);
      } else if (error.type === 'invalid_request_error') {
        setStripeError(error.message);
      } else {
        setStripeError(
          'Stripe is detecting an error in your information, please recheck your inputs.',
        );
        console.info('catch', error);
      }
      setSubmitting(false);
    }
  };

  return (
    <MyModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={bem()}
      overlayClassName={bem('overlay')}
    >
      <FontAwesomeIcon
        className="finalizeReviewModal__exit"
        onClick={onClose}
        icon={['fal', 'times']}
      />
      <h2>Important</h2>
      <p>
        <FontAwesomeIcon icon="exclamation-circle" />
        Please make sure this information is correct before finalizing. This data may be
        used in important legal documents or tasks, and will be stored on this entity's
        account.
      </p>
      <div className="finalizeReviewModal__info">
        <ReviewInfo {...reviewInfo} />
      </div>
      {isAdmin && !!room_use && !isDocumentTransaction && (
        <p className="finalizeReviewModal__room">
          <FontAwesomeIcon icon="exclamation-circle" />
          Finalizing will generate a Data Room.
        </p>
      )}
      {isAdmin && !!room_use && !!isDocumentTransaction && (
        <p className="finalizeReviewModal__room">
          <FontAwesomeIcon icon="exclamation-circle" />
          Finalizing will generate a Deal Room.
        </p>
      )}
      {isDocumentTransaction && (
        <>
          {isDevEnv && (
            <div className="finalizeReviewModal__redlining">
              <InputStyledCheckbox
                checked={isDevPandadoc}
                label="Generate Pandadoc documents on finalize(demo-only feature)"
                name="dev-send-panda-doc"
                onChange={() => setDevPandadoc(!isDevPandadoc)}
                value={isDevPandadoc}
              />
            </div>
          )}
          <div className="finalizeReviewModal__redlining">
            <h4>How do you want your document links to be sent?</h4>
            <InputStyledCheckbox
              checked={isAutoSend}
              inputHelpAlign="center"
              label="Automatically"
              name="enable-send-document-link"
              onChange={() => setIsAutoSend(true)}
              type="radio"
              value={isAutoSend}
            />
            <InputStyledCheckbox
              checked={!isAutoSend}
              inputHelpAlign="center"
              label="Manually"
              name="disable-send-document-link"
              onChange={() => setIsAutoSend(false)}
              type="radio"
              value={!isAutoSend}
            />
          </div>
          {!!isRedlineAvailable && (
            <div className="finalizeReviewModal__redlining">
              <h4>Do you want to enable document redlining/editing?</h4>
              <InputStyledCheckbox
                checked={isDocEditingEnabled}
                inputHelpAlign="center"
                label="Enable Redlining"
                isRequired
                name="enable-document-editing"
                onChange={() => setIsDocEditingEnabled(true)}
                type="radio"
                value={isDocEditingEnabled}
              />
              <InputStyledCheckbox
                checked={!isDocEditingEnabled}
                inputHelpAlign="center"
                label="Disable Redlining"
                name="disable-document-editing"
                onChange={() => setIsDocEditingEnabled(false)}
                type="radio"
                value={!isDocEditingEnabled}
              />
              <p>
                <span className="finalizeReviewModal__asterik">*</span>
                If enabled, any document recipient can make a document edit suggestion.
                When a suggestion is made, document recipients and reviewers will be
                notified through email. You can toggle redlining on/off even after you
                finalize.
              </p>
            </div>
          )}
        </>
      )}
      {!isOutsideForm && (
        <div className="finalizeReviewModal__info visible">
          <h4>
            <FontAwesomeIcon icon="exclamation-circle" />
            Once you click 'Finalize', we will use your information to begin processing
            your Task.
            {!!documentCredits && (
              <>
                {' '}
                Finalizing will use <span>{documentCredits || 'zero'}</span> document
                credit(s)
                {!!creditsCoveredBy && ` (covered by ${creditsCoveredBy})`}
              </>
            )}
          </h4>
        </div>
      )}
      {!!isTotalShowing && !isOutsideForm && (
        <div className="finalizeReviewModal__info visible">
          <h4>
            <FontAwesomeIcon icon="exclamation-circle" />
            {!!feesCoveredBy && `Billing covered by ${feesCoveredBy} `}
            {!feesCoveredBy && (
              <>
                {'Your Account will be billed '}
                <span>
                  {'$' + ((isAddonsHidden && feesTotal) || amountTotal)}
                  <DropdownMenu
                    align="center"
                    upwards
                    className="finalizeReviewModal__help"
                    close={() => setPricingOpen(false)}
                    closeOnInsideClick={false}
                    isOpen={isPricingOpen}
                    toggle={
                      <sup>
                        <FontAwesomeIcon
                          onClick={() => setPricingOpen(!isPricingOpen)}
                          icon={['fal', 'info-circle']}
                        />
                      </sup>
                    }
                  >
                    {fees.length > 0 && (
                      <>
                        <h4>Additional Fees:</h4>
                        {fees.map((fee, feeInd) => (
                          <li key={`product-fee-${feeInd}`}>
                            {`- ${fee.label} - ${
                              (!!feesCoveredBy && `Covered by ${feesCoveredBy} -`) ||
                              (fee.is_annual && fee.amount) ||
                              '$' + fee.amount
                            }`}
                            {!feesCoveredBy && fee.comment && (
                              <ul>
                                <li>{fee.comment}</li>
                              </ul>
                            )}
                          </li>
                        ))}
                      </>
                    )}
                    {addons.length > 0 && !isAddonsHidden && (
                      <>
                        <h4>Add-ons:</h4>
                        <ul>
                          {addons.map((addon, addonInd) => (
                            <li key={`product-addon-${addonInd}`}>
                              {`- ${addon.label} - ${
                                !!feesCoveredBy
                                  ? `Covered by ${feesCoveredBy} -`
                                  : '$' + addon.amount + '/'
                              }`}
                              {(!!feesCoveredBy && ' ') ||
                                (addon.period === 'year' && 'Yr') ||
                                'Mo'}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {(fees.length > 0 || addons.length > 0) &&
                      !get(fees, '[0].is_annual', false) && (
                        <h4>
                          Total -{' '}
                          {(!!feesCoveredBy && `Covered by ${feesCoveredBy}`) ||
                            '$' + ((isAddonsHidden && feesTotal) || amountTotal)}
                        </h4>
                      )}
                  </DropdownMenu>
                </span>
              </>
            )}
            when you finalize.
          </h4>
        </div>
      )}
      {!!isPaymentMethodShowing && !isPaymentsHidden && (
        <div className={bem('payment')}>
          <h3>Payment</h3>
          <CardElement
            className={bem('stripeContainer')}
            options={{
              hideIcon: true,
              style: {
                base: {
                  fontSize: '18px',
                  '::placeholder': {
                    color: '#afbdc5',
                    fontWeight: 400,
                    fontSize: '16px',
                    fontStyle: 'italic',
                  },
                },
              },
            }}
          />
          <div className={bem('inputRow')}>
            <InputStyledTextField
              label="Name on Card"
              name="name"
              icon="user"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <InputSelect
              label="Country"
              name="country"
              options={TwoCharCountryData}
              isClearable={false}
              value={country}
              onChange={val => {
                setCountry(val);
              }}
            />
          </div>
          {!isEmpty(stripeError) && (
            <div className={bem('stripeError')}>
              <h4>
                <strong>Error: </strong> {stripeError}
              </h4>
            </div>
          )}
          <InputStyledCheckbox
            checked={isDefault}
            value={isDefault}
            isDisabled={true}
            name="is_default"
            label={<span className="checkbox__label">Use as default Payment Method</span>}
            onChange={e => setIsDefault(!isDefault)}
          />
        </div>
      )}
      {!!isPaymentMethodShowing && isPaymentsHidden && (
        <div className={bem('payment')}>
          <h3>Payment</h3>
          <h4>
            Payment covered by {brandMeta.display_name || 'your Package'} is currently in
            the process of getting set up. To better know when that will be set up, please{' '}
            <Button
              buttonType="link"
              size="sm"
              href={`mailto:${brandMeta.support_email || 'support@savvi.legal'}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Support
            </Button>{' '}
          </h4>
          {!isEmpty(stripeError) && (
            <div className={bem('stripeError')}>
              <h4>
                <strong>Error: </strong> {stripeError}
              </h4>
            </div>
          )}
        </div>
      )}
      <div className="finalizeReviewModal__buttons">
        <Button buttonType="secondary" onClick={() => onClose()} size="md">
          Back
        </Button>
        <Button
          onClick={onFinalizeClick}
          isFetching={submitting}
          isDisabled={submitting}
          size="md"
        >
          {(isAssessment && 'Generate Tasks') ||
            (isOutsideForm && 'Submit') ||
            'Finalize'}
        </Button>
      </div>
    </MyModal>
  );
};

export default FinalizeReviewModal;
