import { combineReducers } from 'redux';

import { apiErrorsReducer, createReducer, fetchingReducer } from '../../utils';
import {
  ADD_ACH_SUCCESS,
  ADD_METHOD_SUCCESS,
  GET_PLAID_TOKEN_SUCCESS,
  GET_METHODS_SUCCESS,
  MERGE_SUBSCRIPTION,
  REMOVE_METHOD,
  SET_DEFAULT_SUCCESS,
  SET_INVOICE,
  SET_MERCURY_BANKS,
  GET_SUBSCRIPTION_PREVIEW_SUCCESS,
  VERIFY_ACH_SUCCESS,
} from './types';

const isFetching = fetchingReducer('billing');

const plaidTokenReducer = createReducer('')({
  [GET_PLAID_TOKEN_SUCCESS]: (state, { payload }) => payload.link_token,
});

const methodsReducer = createReducer([])({
  [GET_METHODS_SUCCESS]: (state, { payload }) => {
    return payload.methods;
  },
  [ADD_METHOD_SUCCESS]: (state, { payload }) => payload.methods,
  [SET_DEFAULT_SUCCESS]: (state, { payload }) => payload.methods,
  [REMOVE_METHOD]: (state, { payload }) =>
    state.filter(method => method.id !== payload.methodId),
});

const defaultMethodReducer = createReducer('')({
  [GET_METHODS_SUCCESS]: (state, { payload }) => payload.default,
  [ADD_METHOD_SUCCESS]: (state, { payload }) => payload.default,
  [SET_DEFAULT_SUCCESS]: (state, { payload }) => payload.default,
});

const invoiceReducer = createReducer([])({
  [SET_INVOICE]: (state, { payload }) => payload,
});

const subscriptionReducer = createReducer({})({
  [MERGE_SUBSCRIPTION]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
});

const subscriptionPreviewReducer = createReducer({})({
  [GET_SUBSCRIPTION_PREVIEW_SUCCESS]: (state, { payload }) => ({ ...payload.invoice }),
});

const achReducer = createReducer({})({
  [ADD_ACH_SUCCESS]: (state, { payload }) => ({ ...state, payload }),
  [VERIFY_ACH_SUCCESS]: (state, { payload }) => ({ ...state, payload }),
});

const accountMercuryBanksReducer = createReducer([])({
  [SET_MERCURY_BANKS]: (state, { payload }) => [...payload],
});

const isSynced = createReducer(false)({});

export default combineReducers({
  isFetching,
  methods: methodsReducer,
  invoices: invoiceReducer,
  subscription: subscriptionReducer,
  subscriptionPreview: subscriptionPreviewReducer,
  ach: achReducer,
  plaidToken: plaidTokenReducer,
  apiErrors: apiErrorsReducer('billing'),
  defaultMethod: defaultMethodReducer,
  accountMercuryBanks: accountMercuryBanksReducer,
  isSynced,
});
