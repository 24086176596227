import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../common';
import DropdownMenu from 'react-dd-menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccountInfo } from '../../redux/modules/Formation/selectors';
import OrgButton from '../OrgButton';
import { WhatsNewModalToggle } from '../WhatsNewModal';

import './ConsistentTopbar.scss';
import handlePath from '../../utils/handlePath';
import { useLocation, useParams } from 'react-router';
import { IsAuthenticated, getAuthUser } from '../../redux/modules/User/selectors';
import CompanyDropdown from '../CompanyDropdown';
import SearchBar from '../SearchBar';
import UseWindowSize from '../../utils/UseWindowSize';

const Topbar = ({ isTeamView, isPortalView, workbenchParams }) => {
  const location = useLocation();
  const { companyId } = useParams();
  const { width } = UseWindowSize();
  const { step } = workbenchParams || {};
  const accountInfo = useSelector(AccountInfo);
  const isAuthenticated = useSelector(IsAuthenticated);
  const { credits_info: { limit, used } = {}, has_partner_subscriptions } = accountInfo;
  const user = useSelector(getAuthUser);
  const [isDocumentCountModalOpen, setDocumentCountModal] = useState(false);
  const teamRoutes = ['company-select', 'teams', 'dashboard', 'lp-portal'];
  const isTeam = teamRoutes.findIndex(e => location.pathname.includes(e)) !== -1;

  const availableCredits = !isNaN(limit) && limit - used;

  const isVerifyHiding = localStorage.getItem('hide_verify');

  const isUpgradePlanShowing =
    (!!user.email_verified || !!isVerifyHiding) &&
    !isNaN(limit) &&
    availableCredits < 6 &&
    !has_partner_subscriptions &&
    width > 576;

  const plansPath = handlePath(
    {
      pathname: '/plans',
      state: { background: location },
    },
    companyId,
  );

  return (
    <div className="consistentTopbar">
      <ul className="consistentTopbar__nav">
        <ul className="consistentTopbar__secondary-nav">
          {((isPortalView && step === 'portal') || isTeamView) && <SearchBar />}
          {isUpgradePlanShowing && (
            <li className="consistentTopbar__navListItem right">
              <DropdownMenu
                align="left"
                className="consistentTopbar__credits-popup"
                close={() => setDocumentCountModal(false)}
                closeOnInsideClick={true}
                isOpen={isDocumentCountModalOpen}
                toggle={
                  <div
                    className="consistentTopbar__credits"
                    onClick={() => setDocumentCountModal(!isDocumentCountModalOpen)}
                  >
                    <div className="consistentTopbar__credits-icon">
                      <h4>{availableCredits}</h4>
                      <FontAwesomeIcon icon={['fal', 'file']} />
                    </div>
                    <sup className="consistentTopbar__credits-help">
                      <FontAwesomeIcon icon="exclamation-circle" />
                    </sup>
                  </div>
                }
              >
                <li>
                  You only have
                  <span className="consistentTopbar__credits-number">
                    {availableCredits}
                  </span>
                  Document Credit(s) remaining on this plan.
                  <br />
                  <br />
                  Credits are used when tasks are submitted that generate or send legal
                  documents or filings.{' '}
                  <Button buttonType="link" to={plansPath} size="sm">
                    Upgrade your Plan
                  </Button>{' '}
                  to get more credits.
                </li>
              </DropdownMenu>
              <Button
                className="consistentTopbar__credits-upgrade"
                buttonType="link"
                to={plansPath}
                size="sm"
              >
                Upgrade Plan
              </Button>
            </li>
          )}
          {isAuthenticated && width > 576 && (
            <>
              <WhatsNewModalToggle />
              <li className="consistentTopbar__item consistentTopbar__item--borderLeft">
                <CompanyDropdown isTeam={isTeam} />
              </li>
            </>
          )}
          <li className="consistentTopbar__item consistentTopbar__item--borderLeft">
            <OrgButton accountInfo={accountInfo} darkText />
          </li>
        </ul>
      </ul>
    </div>
  );
};

// Topbar.defaultProps = {
//   isAuthenticated: false,
// };

export default Topbar;
