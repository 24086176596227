import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js/pure';
import { loadStripe } from '@stripe/stripe-js';
import { ConnectedRouter } from 'connected-react-router';

import PropTypes from 'prop-types';

import messages from 'box-ui-elements/i18n/en-US';

import App from './App';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Root = ({ store, history }) => {
  // const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_KEY), []);

  return (
    <Provider store={store}>
      <IntlProvider locale="en-US" messages={messages}>
        <ConnectedRouter history={history}>
          <Elements stripe={stripePromise}>
            <Route component={App} />
          </Elements>
        </ConnectedRouter>
      </IntlProvider>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
};
export default Root;
