import {
  CLEAR_ERRORS,
  MERGE_SUBSCRIPTION,
  REMOVE_METHOD,
  SET_ERROR,
  SET_INVOICE,
  SET_SUBSCRIPTION_PREVIEW,
  SET_MERCURY_BANKS,
} from './types';

export function removeMethod(methodId) {
  return {
    type: REMOVE_METHOD,
    payload: { methodId },
  };
}

export function setInvoice(payload) {
  return {
    type: SET_INVOICE,
    payload,
  };
}

export function mergeSubscription(payload) {
  return {
    type: MERGE_SUBSCRIPTION,
    payload,
  };
}

export function setSubscriptionPreview(payload) {
  return {
    type: SET_SUBSCRIPTION_PREVIEW,
    payload,
  };
}

export function setError(payload) {
  return {
    type: SET_ERROR,
    payload,
  };
}

export function clearErrors() {
  return {
    type: CLEAR_ERRORS,
  };
}

export function setAccountMercuryBanks(payload) {
  return {
    type: SET_MERCURY_BANKS,
    payload,
  };
}
