import { isObject } from 'lodash-es';

const handlePathWithUrl = (path, url) =>
  isObject(path)
    ? {
        ...path,
        pathname: `${url}` + path.pathname,
      }
    : `${url}` + path;

const handlePathWithCompanyId = (path, companyId) => {
  let pathname = path?.pathname || path;
  let isPartialPath = !pathname.includes('/c/');
  let isAccountIdZero = pathname.includes('/c/0/');

  if (isPartialPath) {
    pathname = `/c/${companyId || '0'}${pathname}`;
  } else if (isAccountIdZero && companyId !== 0) {
    pathname = pathname.replace('/c/0', `/c/${companyId}`);
  }
  if (isObject(path)) {
    return {
      ...path,
      pathname,
    };
  }
  return pathname;
};

export default (path, companyId, url) =>
  url ? handlePathWithUrl(path, url) : handlePathWithCompanyId(path, companyId);

export const handleExternalUrlWithCompanyId = (path, companyId) => {
  let updatedPath = path;
  if (updatedPath.includes('/c/0')) {
    updatedPath = updatedPath.replace('/c/0', `/c/${companyId}`);
  } else if (!path.includes('/c/') && !path.includes('http')) {
    updatedPath = `/c/${companyId}${path}`;
  }
  if (!path.includes('http')) {
    let brandMeta = JSON.parse(localStorage.getItem('brandMeta') || '{}');
    if (
      !brandMeta.web_base_url ||
      brandMeta.web_base_url === 'https://app.example.com' ||
      window.location.href.includes('localhost')
    ) {
      brandMeta.web_base_url = process.env.REACT_APP_BASEURL;
    }
    updatedPath = brandMeta.web_base_url + updatedPath;
  }
  return updatedPath;
};

export const handleRoute = (path, routePath) => routePath + path;
