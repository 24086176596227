import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button, MyModal } from '../common';

import './TermsOfServiceModal.scss';
// Modal.setAppElement('#root');

const TermsOfService = () => {
  const history = useHistory();
  const { action, goBack, replace } = history;
  const location = useLocation();
  const { pathname, state } = location;
  const { background } = state || {};

  const onClose = () => {
    if (action !== 'POP') {
      goBack();
    } else if (background) {
      replace(background);
    } else {
      replace('/c/0/company-select');
    }
  };
  return (
    <MyModal
      isOpen={pathname.includes('/terms')}
      onRequestClose={onClose}
      contentLabel={'Savvi Terms of Service'}
      className="termsOfServiceModal"
      overlayClassName="termsOfServiceModal__overlay"
    >
      <div className="termsOfServiceModal__header">
        <h1>Terms of Service</h1>
        <FontAwesomeIcon
          className="termsOfServiceModal__exit"
          onClick={onClose}
          icon="times"
        />
      </div>
      <p>
        In addition to the{' '}
        <Button
          buttonType="link"
          size="sm"
          href="http://docs.wixstatic.com/ugd/342422_fa8385a417404f138a687a08f8c5bc15.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use and Privacy Policy
        </Button>{' '}
        applicable to all services provided by Savvi Technologies, Inc., or any of its
        affiliates (collectively, “Savvi”), which are incorporated herein by reference,
        the following terms apply to your use of this service:{' '}
      </p>
      <p>
        You acknowledge and agree that the provision of any products, information,
        materials or services by Savvi or via www.savvi.legal or any related websites
        (collectively, the "Site") does not constitute the provision of legal advice, tax
        advice or any other professional advice by Savvi, and does not create any
        attorney-client or other confidential or special relationship between Savvi and
        you or any other party.{' '}
      </p>
      <p>
        You further agree and acknowledge that the results of this form, any paperwork, or
        communications with Savvi employees are provided as a "DIY" service by Savvi and
        thus Savvi makes no representation, guarantee or warranty that any documents,
        information or materials provided will actually be suitable for use in your
        business. You acknowledge that you should seek advice from an attorney licensed in
        your applicable jurisdiction(s) and also relevant tax professionals before relying
        on any products or services provided by Savvi or via the Site.{' '}
      </p>
      <p>
        By relying on the Savvi's services, you assume all risk and liability that may
        result, and hereby release Savvi from any claims that may arise against it from
        your use of the Savvi Pre-Diligence Assessment and subsequent deliverables and
        communications.{' '}
      </p>
      <p>
        SAVVI SERVICES ARE PROVIDED ON AN “AS IS” BASIS, AND SPECIFICALLY DISCLAIMS ALL
        WARRANTIES, TERMS, REPRESENTATIONS AND CONDITIONS WHETHER EXPRESS, IMPLIED, OR
        STATUTORY, AND INCLUDING ANY WARRANTIES, TERMS, REPRESENTATIONS AND CONDITIONS OF
        MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR
        NONINFRINGEMENT. WHILE WE TAKE PRECAUTIONS TO PROTECT THE SECURITY OF YOUR
        INFORMATION, WE CANNOT GUARANTEE IT.{' '}
      </p>
      <p>
        You may not use any of the services or products provided by Savvi for any illegal
        purpose, including for purposes of avoiding penalties under the Internal Revenue
        Code (or equivalent in the relevant jurisdiction) or promoting, marketing or
        recommending to another party any transaction or matter.{' '}
      </p>
      <p>
        You confirm that you have the authority to share any information that you provide
        about any entity or other individuals. These terms and the products and services
        available on the Site may be changed at any time by Savvi, with or without notice,
        at Savvi's sole discretion. Your agreement to these terms is required before you
        are permitted to use any of Savvi's or the Site's products and services.
      </p>
    </MyModal>
  );
};

export default TermsOfService;
