import { RSAA } from 'redux-api-middleware';

import { requestHeaders } from '../../utils/api';

import * as types from './types';
import { setBrandMeta } from './actions';

export {
  openTermsModal,
  closeTermsModal,
  openUserDropdown,
  closeUserDropdown,
  setNotice,
  clearNotice,
} from './actions';

export const fetchBrandMeta = () => {
  const {
    FETCH_BRAND_META_REQUEST: REQUEST,
    FETCH_BRAND_META_SUCCESS: SUCCESS,
    FETCH_BRAND_META_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/public/brand',
        method: 'GET',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setBrandMeta(actionResponse.payload));
      const shouldRefreshPage = !localStorage.getItem('brandMeta');
      localStorage.setItem('brandMeta', JSON.stringify(actionResponse.payload));
      if (shouldRefreshPage) {
        // window.location.reload();
        document.title = (actionResponse.payload?.assets || {}).title;
        // document.head.querySelector('link[rel="icon"]').href = (brandMeta?.assets || {}).;
      }
      return await actionResponse.payload;
    } else {
      throw await actionResponse.payload;
    }
  };
};
