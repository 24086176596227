import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import paperOSLogo from '../../assets/images/paperOS_with_logo.svg';

import { Formik } from 'formik';

import { operations as UI } from '../../redux/modules/UI';
import { operations } from '../../redux/modules/User';
// import { registerSchema } from '../../utils/Validations';
import Register from './Register';
import { BrandMeta } from '../../redux/modules/UI/selectors';

class RegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordPlainText: false,
    };
  }

  handleOnLoginClick = () =>
    this.props.history.replace({
      ...this.props.location,
      pathname: '/login',
    });

  handleTogglePassword = () => {
    return this.setState({
      isPasswordPlainText: !this.state.isPasswordPlainText,
    });
  };

  render() {
    const {
      brandMeta: {
        assets: {
          account_business_type,
          partner_slug,
          logo_with_paperos,
          show_business_types,
        } = {},
      } = {},
      isFetching,
      isModalOpen,
      location: { search, state: { email = '', redirectPathname = '' } = {} },
      submitForm,
      toggleModal,
    } = this.props;
    const isRoomRedirect = redirectPathname.includes('data-room');

    const query = new URLSearchParams(search);
    const fl360sso = query.get('fl360_sso') || localStorage.getItem('fl360_sso');
    const isWhiteLabel = window.location.href.includes('paperos');
    const wordmark = logo_with_paperos || paperOSLogo;
    const logoClasses = `
    register__logo ${
      (wordmark.includes('savvi') && 'register__logo--savvi') ||
      (wordmark.includes('fund_launch') && 'register__logo--fundLaunch') ||
      ''
    }`;

    return (
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email,
          create_workspace: '1',
          company_name: '',
          password: '',
          tos: false,
          business_type:
            (account_business_type && {
              label: account_business_type,
              value: account_business_type,
            }) ||
            '',
        }}
        onSubmit={({
          business_type,
          company_name,
          email,
          create_workspace,
          first_name,
          last_name,
          password,
        }) => {
          if (create_workspace === '0') {
            create_workspace = false;
          } else {
            create_workspace = true;
          }
          const userBody = {
            email,
            password,
            first_name,
            family_name: last_name,
            create: true,
          };
          const companyBody = {
            company_name,
            features: [
              { feature_type_id: 881, value: business_type?.value || '' },
              { feature_type_id: 1583, value: partner_slug || '' },
            ],
          };
          submitForm(userBody, companyBody, create_workspace, isRoomRedirect);
        }}
      >
        {formikProps => {
          return (
            <Register
              {...formikProps}
              fl360sso={fl360sso}
              isFetching={isFetching}
              isModalOpen={isModalOpen}
              isPasswordPlainText={this.state.isPasswordPlainText}
              isRoomRedirect={isRoomRedirect}
              isWhiteLabel={isWhiteLabel}
              handleTogglePassword={this.handleTogglePassword}
              handleOnLoginClick={this.handleOnLoginClick}
              wordmark={wordmark}
              logoClasses={logoClasses}
              show_business_types={show_business_types}
              toggleModal={toggleModal}
            />
          );
        }}
      </Formik>
    );
  }
}

RegisterContainer.propTypes = {
  errors: PropTypes.object,
  isFetching: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
};

RegisterContainer.defaultProps = {
  errors: {},
  isFetching: false,
  isModalOpen: false,
  toggleModal: () => null,
};

const mapStateToProps = state => {
  const { Auth, UI } = state;
  const { isFetching } = Auth;
  return {
    isFetching,
    isModalOpen: UI.isTermsModalOpen,
    brandMeta: BrandMeta(state),
  };
};

const mapDispatchToProps = dispatch => {
  const { createUser } = operations;
  const { openTermsModal, closeTermsModal } = UI;
  return {
    submitForm: (userBody, companyBody, isCreatingWorkspace, isRoomRedirect) =>
      dispatch(createUser(userBody, companyBody, isCreatingWorkspace, isRoomRedirect)),
    toggleModal: open =>
      !!open ? dispatch(openTermsModal()) : dispatch(closeTermsModal()),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(RegisterContainer);
